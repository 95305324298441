const uk = {
  About: 'Про нас',
  AboutAnyQuestionsTitle:
    "Якщо у вас є питання або пропозиції, будь ласка, зв'яжіться з нами за допомогою електронної пошти",
  AboutAnyQuestionsDescription:
    "Ми цінуємо Ваш зворотній зв'язок і намагатимемося відповісти на ваше повідомлення в розумний строк. Будь ласка, майте на увазі, що через велику кількість запитів відповідь може зайняти кілька днів. Ми приносимо вибачення за будь-які незручності і дякуємо за ваше розуміння",
  Add: 'Додати',
  AddItem: 'Додати активність',
  AdditionalInfo: 'Додаткова інформація',
  AddSection: 'Додати групу',
  Ago: 'назад',
  Agree: 'Продовжуючи, ви погоджуєтеся з нашими',
  AlreadyExists: 'Така категорія вже існує',
  And: ' та ',
  AndroidApp: 'Застосунок для Android',
  AppTitle: 'Навігатор Активності',
  Assessment: 'Оцінка',
  AssignedDeviceTitle: 'ПРИСТРІЙ БУЛО ЗАКРІПЛЕНО ЗА ЕЛЕКТРОННОЮ ПОШТОЮ',
  AssignedDeviceBody1:
    'Ви намагаєтеся призначити нового користувача на цей пристрій.',
  AssignedDeviceBody2:
    'У цьому випадку УСЯ інформація для попереднього користувача з',
  AreYouSure: 'Ви впевнені, що хочете цього?',
  AutomaticUpdate: 'Автоматичне оновлення',
  Back: 'Повернутися',
  Benefits: 'Переваги',
  BenefitsEarlyAccess: 'Ранній доступ до нових функцій',
  BenefitsExchangeDescription:
    'Ви зможете легко зібрати ваші дані з інших джерел в один .xlsx файл і імпортувати їх в додаток всього одним кліком',
  BenefitsExchangeTitle: 'Імпорт та експорт даних у форматі Excel',
  BenefitsExchangeWarning:
    'Під час імпорту всі дані з програми будуть видалені, тому необхідно виконати Експорт і додавати дані до згенерованого файлу. Також не рекомендується видаляти системні дані, які були введені в файл Excel, оскільки це може призвести до помилок під час імпорту',
  BenefitsInstantStatisticsUpdate: 'Миттєве оновлення статистики',
  BenefitsInstantStatisticDescription:
    'Для звичайних користувачів статистика оновлюється один раз на місяць. Для преміум-користувачів ця обмеження знімається',
  BuyCoffee: 'На каву',
  ByDays: 'По днях',
  Cancel: 'Відмінити',
  CardNo: 'Номер картки',
  CardExpirationDate: 'Строк дії (ММ/РР)',
  CardInvalidCode: 'Введіть 16 цифр',
  CardInvalidExpiration: 'Введіть ММ/РР',
  CardInvalidCVV: 'Введіть 3 цифри',
  ChooseFile: 'Виберіть файл',
  Code: 'Код',
  CodeCVV: 'CVV/CVC код',
  CodeIncorrect: 'Неправильний код. Будь ласка, спробуйте ще раз',
  CreateProfile: 'Реєстрація',
  Confirm: 'Підтвердити',
  ConfirmPassword: 'Підтвердити пароль',
  Contribute: 'Зробити свій внесок',
  ConsecutiveDays: 'днів поспіль',
  Crossed: 'перетини',
  CurrentWeek: 'Поточний тиждень',
  DarkTheme: 'Темна',
  Day: 'день',
  Days: 'днів',
  Days2: 'дні',
  DayAgo: 'вчора',
  DaysAgo: 'дні назад',
  Delete: 'Видалити',
  Details: 'Детальніше',
  Done: 'Зроблено',
  done: 'зроблено',
  DoneConsecutiveDays: 'Виконано днів поспіль',
  Download: 'Завантажити',
  EN: 'Англійська',
  ENMobile: 'Англ.',
  EnterCode: 'Введіть код, надісланий на вашу електронну пошту',
  Email: 'Ел. пошта',
  EmailAddress: 'Пошта',
  EmailCheck:
    'Будь ласка, перевірте свою поштову скриньку, щоб встановити новий пароль',
  EmailInUse: 'Ця електронна адреса вже використовується',
  EmailInvalid: 'Невірна електронна адреса',
  EmailNotFound: 'Користувача з такою адресою електронної пошти не знайдено',
  Error: 'Помилка',
  ErrorBody:
    'Послуга тимчасово недоступна, можливі причини: технічне обслуговування, цей функціонал не працює, немає інтернету',
  Exit: 'Вихід',
  Expires: 'термін дії до',
  Expired: 'термін дії закінчився',
  ExpiredLink: 'Закінчився термін дії посилання',
  Export: 'Експорт',
  FirstDayOfTheWeek: 'Перший день тижня',
  Finish: 'Завершити',
  GetStarted: 'Почати',
  GoToDate: 'Вкажіть дату (дд.мм.рррр)',
  GoTo: 'Перейти',
  GroupTitle: 'Назва групи',
  Home: 'На головну',
  Hours: 'години',
  HiddenItems: 'Приховані активності',
  Import: 'Імпорт',
  IncorrectLoginInfo: "Ім'я користувача або пароль невірні",
  InCrypto: 'Криптою',
  InitialSettings: 'Налаштування за замовчуванням',
  ItemDescription: 'Опис активності',
  ItemForm: 'Редагування активності',
  ItemTargetValue: 'Бажана кількість на день',
  ItemTitle: 'Назва активності',
  Items: 'Активності',
  Language: 'Мова',
  Latest: 'Останній раз',
  LatestUpdate: 'Останнє оновлення',
  Lifetime: 'НАЗАВЖДИ',
  LightTheme: 'Світла',
  Loading: 'Завантаження...',
  LoginPage: 'Сторінка входу',
  Logout: 'Вихід',
  MB: 'МБ',
  max: 'макс.',
  maxPerDay: 'макс. за день',
  maximum: 'максимум',
  Maximum: 'Максимум',
  MaximumPerDay: 'Максимум в день',
  Menu: 'Меню',
  minimum: 'мінімум',
  Minimum: 'Мінімум',
  MinimumPerDay: 'Мінімум в день',
  Minutes: 'хвилини',
  Missed: 'Пропущено',
  MissedConsecutiveDays: 'Пропущено днів поспіль',
  Mobile: 'мобільний',
  Monday: 'Понеділок',
  Month: 'Місяць',
  More: 'більше',
  MoreDaysAgo: 'днів назад',
  Negative: 'Негативна',
  Next: 'Далі',
  NewSection: 'Нова група',
  NoConnection: "Відсутнє з'єднання з сервером",
  NoDetails: 'Недостатньо даних для відображення',
  NoItems: 'Ви ще нічого не додавали',
  None: 'Не вибрано',
  NoSection: 'Без групи',
  Of: 'з',
  Ok: 'Гаразд',
  OneMoreDeviceDetected: 'Виявлено ще один пристрій',
  OneMoreDeviceDetectedBody1:
    'Зверніть увагу, що ця версія підтримує синхронізацію тільки з одним пристроєм. Вам доступні наступні варіанти:',
  Password: 'Пароль',
  PasswordNotMatching: 'Паролі не співпадають',
  Payment: 'Оплата',
  PerDay: 'за день',
  PerMonth: 'на один місяць',
  PerMonthForDetailsPage: 'за місяць',
  PleaseWait: 'Будь ласка, зачекайте...',
  Positive: 'Позитивна',
  Premium: 'Преміум',
  Proceed: 'Продовжити',
  ProcessingOrder: 'Ваше замовлення обробляється',
  Profile: 'Профіль',
  PrivacyPolicy: 'Політикою конфіденційності',
  Purchase: 'Придбати',
  Records: 'записів',
  Refresh: 'Оновити',
  ReleaseDate: 'Дата релізу',
  RegisterText1_guarantee_delivery:
    'Щоб забезпечити доставку наших листів, будь ласка, додайте',
  RegisterText2_contact_list_spam:
    'у список контактів вашої поштової програми. Це допоможе уникнути потрапляння листів у папку «Спам».',
  RegisterText3_After_adding_email:
    'Після додавання цієї адреси та натискання кнопки «Далі» нижче у цій формі, будь ласка, дотримуйтесь інструкцій для завершення процесу реєстрації.',
  RegisterText4_you_can_login:
    "Після реєстрації також з'явиться доступ на наш веб-сайт:",
  RegisterText5_different_approach:
    'Функціонал веб-сайту і застосунку різняться, тим самим вносячи різноманітність під час виконання повсякденних завдань.',
  RegisterText6_ask_questions:
    "Якщо у вас виникнуть запитання, будь ласка, зв'яжіться з нами електронною поштою",
  RegistrationSuccessful: 'Вітаємо, ваша реєстрація пройшла успішно',
  Remove: 'Видалити',
  RemoveSectionAllow: 'Ви впевнені, що ця група вам більше не знадобиться?',
  RemoveSectionDeny:
    "Цю групу не може бути видалено, поки до неї прив'язані активності",
  RemoveDevice: 'Видалити пристрій',
  RemovedItem: 'Активність видалено',
  ResetPassword: 'Скидання пароля',
  ResetPasswordForm: 'Скидання пароля',
  RequiredField: "Поле обов'язкове для заповнення",
  RU: 'Російська',
  RUMobile: 'Рос.',
  Saturday: 'Субота',
  Save: 'Зберегти',
  ScheduledUpdate: 'Оновлення за розкладом',
  Seconds: 'секунди',
  Section: 'Група',
  Sections: 'Групи',
  SelectExisting: 'Або виберіть існуюче значення',
  Settings: 'Налаштування',
  SignIn: 'Увійти',
  Size: 'Розмір',
  Synchronize: 'Синхронізувати',
  Synchronized: 'Синхронізовано',
  SyncDate: 'Дата синхронізації',
  SyncEraseAllData:
    'Під час синхронізації з новим пристроєм усі дані на пристрої буде видалено',
  SyncProcess:
    'Відбувається синхронізація з мобільним пристроєм, це не повинно зайняти багато часу',
  SyncReqTitle: 'Необхідна синхронізація',
  SyncNeedErrorBody:
    'З міркувань безпеки даних, сайтом не можна користуватися, поки не буде виконано синхронізацію з мобільним пристроєм. Будь ласка, запустіть/перезапустіть застосунок на мобільному пристрої та дочекайтеся закінчення синхронізації. Якщо синхронізація не потрібна її можна вимкнути за допомогою кнопки "Видалити пристрій"',
  SyncNeedsToBeDone:
    'Перед використанням програми необхідно завершити синхронізацію',
  SyncToMobileTitle: 'Синхронізація з мобільним пристроєм',
  SyncToServerTitle: 'Синхронізація з веб-сайтом',
  SpamFolder:
    "Якщо ви не отримуєте листа протягом певного часу, будь ласка, перевірте 'Спам'",
  Started: 'Перший раз',
  Statistics: 'Статистика',
  Sunday: 'Неділя',
  Syncing: 'Синхронізація',
  SyncFromServerToDevice: 'синхронізацію із сервером на цей пристрій',
  Switch: 'Переключити',
  target: 'ціль',
  TargetAsNumber: 'Бажана кількість має бути числом',
  TargetDone: 'Мету досягнуто',
  TermsOfService: 'Умовами надання послуг',
  TargetNotDone: 'Мету не досягнуто',
  Theme: 'Тема',
  TitleLength: 'Заголовок перевищує максимальну довжину',
  Today: 'Сьогодні',
  TryAgain: 'Ще раз',
  Total: 'Разом',
  TurnOffSync: 'Вимкнути синхронізацію',
  Type: 'Тип',
  TypeValueConsistent: 'Не можна міняти текст на число або навпаки',
  ValidationStage: 'Етап Перевірки',
  Values: 'Значення',
  VerifiedBy: 'Перевірено',
  UA: 'Українська',
  UAMobile: 'Укр.',
  UpdateNow: 'Оновити зараз',
  Upgrade: 'Перейти на ПРЕМІУМ план',
  Unlock: 'Розблокувати',
  Use: 'Використовувати',
  UseDeviceWithoutSync: 'цей пристрій без синхронізації',
  Warning: 'Попередження!',
  Website: 'вебсайт',
  Week: 'Тиждень',
  WillBeRemoved: 'буде видалено',
  Year: 'Рік',
  minutes: 'хвилини',
  number: 'число',
  seconds: 'секунди',
  text: 'текст',
};

export default uk;
